import angular from 'angular';
import { Intl, TranslationResourceProvider } from 'Roblox';
import intlUtilsModule from '../intlUtilsModule';

function languageResourceProvider() {
  const intl = new Intl();
  const pageResources = {};
  let translationResources = null;

  // this will be deleted after rollout
  const getFromLangObject = (resourceKey, params) => {
    let translatedText = pageResources[resourceKey];
    if (!translatedText) {
      translatedText = '';
    } else if (params && Object.keys(params).length > 0) {
      translatedText = intl.f(translatedText, params);
    }

    return translatedText;
  };

  const getFromTranslationResources = (resourceKey, params) => {
    if (translationResources === null) {
      throw new Error('Translation resources is not properly initialized');
    }

    // delegate to the combined translation resources
    return translationResources.get(resourceKey, params);
  };

  const getFromTranslationResourcesOrLangObject = (resourceKey, params) => {
    let translation = null;

    // Get from translation resources
    if (translationResources !== null) {
      translation = getFromTranslationResources(resourceKey, params);
      if (translation) {
        return translation;
      }
    }

    // Fall back to Roblox.Lang
    translation = getFromLangObject(resourceKey, params);
    if (translation) {
      // eslint-disable-next-line no-console
      console.warn(
          `Language key '${resourceKey}' not found in TranslationResources. Defaulting to Roblox.Lang.
          This behavior will be removed soon.`
      );
      return translation;
    }

    // Translation not found
    // eslint-disable-next-line no-console
    console.warn(
      `Language key '${resourceKey}' not found. Please check for any typo or a missing key.`
    );
    return '';
  }

  this.setLanguageKeysFromFile = keys => {
    if (keys && typeof keys === 'object' && !Array.isArray(keys)) {
      angular.extend(pageResources, keys);
    }
  };

  this.setTranslationResources = resources => {
    const combinedTranslationResources = TranslationResourceProvider.combineTranslationResources(
      intl,
      ...resources
    );

    if (translationResources !== null) {
      translationResources = TranslationResourceProvider.combineTranslationResources(
        intl,
        translationResources,
        combinedTranslationResources
      );
    } else {
      translationResources = combinedTranslationResources;
    }
  };

  this.$get = [
    function languageResource() {
      return {
        get: getFromTranslationResourcesOrLangObject,
        intl
      };
    }
  ];
}

intlUtilsModule.provider('languageResource', languageResourceProvider);

export default languageResourceProvider;
